window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.student_network = window.locales.am.student_network || {};window.locales.am.student_network.student_network_event_list = window.locales.am.student_network.student_network_event_list || {};window.locales.am.student_network.student_network_event_list = {...window.locales.am.student_network.student_network_event_list, ...{
    "select_an_event": "አንድ ክስተት ይምረጡ",           
    "no_events_found": "ምንም ክስተቶች አልተገኙም",           
    "date_tbd_label": "ቀን TBD"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.student_network = window.locales.ar.student_network || {};window.locales.ar.student_network.student_network_event_list = window.locales.ar.student_network.student_network_event_list || {};window.locales.ar.student_network.student_network_event_list = {...window.locales.ar.student_network.student_network_event_list, ...{
    "select_an_event": "اختر حدث",
    "no_events_found": "لم يتم العثور على الأحداث",
    "date_tbd_label": "تاريخ غير محدد"
}
};window.locales.en = window.locales.en || {};window.locales.en.student_network = window.locales.en.student_network || {};window.locales.en.student_network.student_network_event_list = window.locales.en.student_network.student_network_event_list || {};window.locales.en.student_network.student_network_event_list = {...window.locales.en.student_network.student_network_event_list, ...{
    "select_an_event": "Select an Event",
    "no_events_found": "No Events Found",
    "date_tbd_label": "Date TBD"
}
};window.locales.es = window.locales.es || {};window.locales.es.student_network = window.locales.es.student_network || {};window.locales.es.student_network.student_network_event_list = window.locales.es.student_network.student_network_event_list || {};window.locales.es.student_network.student_network_event_list = {...window.locales.es.student_network.student_network_event_list, ...{
    "select_an_event": "Selecciona un evento",
    "no_events_found": "No se encontraron eventos",
    "date_tbd_label": "Fecha indecisa"
}
};window.locales.it = window.locales.it || {};window.locales.it.student_network = window.locales.it.student_network || {};window.locales.it.student_network.student_network_event_list = window.locales.it.student_network.student_network_event_list || {};window.locales.it.student_network.student_network_event_list = {...window.locales.it.student_network.student_network_event_list, ...{
    "select_an_event": "Seleziona un evento",
    "no_events_found": "Nessun evento trovato",
    "date_tbd_label": "Data indecisa"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.student_network = window.locales.zh.student_network || {};window.locales.zh.student_network.student_network_event_list = window.locales.zh.student_network.student_network_event_list || {};window.locales.zh.student_network.student_network_event_list = {...window.locales.zh.student_network.student_network_event_list, ...{
    "select_an_event": "选择一个活动",           
    "no_events_found": "未发现活动",           
    "date_tbd_label": "日期待定"           
}
};