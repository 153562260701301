window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.student_network = window.locales.am.student_network || {};window.locales.am.student_network.student_network_event_type_filters = window.locales.am.student_network.student_network_event_type_filters || {};window.locales.am.student_network.student_network_event_type_filters = {...window.locales.am.student_network.student_network_event_type_filters, ...{
    "title": "በክስተት አይነት ያጣሩ",           
    "submit_button_text": "ያመልክቱ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.student_network = window.locales.ar.student_network || {};window.locales.ar.student_network.student_network_event_type_filters = window.locales.ar.student_network.student_network_event_type_filters || {};window.locales.ar.student_network.student_network_event_type_filters = {...window.locales.ar.student_network.student_network_event_type_filters, ...{
    "title": "تصفية حسب نوع الحدث",
    "submit_button_text": "تطبيق"
}
};window.locales.en = window.locales.en || {};window.locales.en.student_network = window.locales.en.student_network || {};window.locales.en.student_network.student_network_event_type_filters = window.locales.en.student_network.student_network_event_type_filters || {};window.locales.en.student_network.student_network_event_type_filters = {...window.locales.en.student_network.student_network_event_type_filters, ...{
    "title": "Filter by Event Type",
    "submit_button_text": "Apply"
}
};window.locales.es = window.locales.es || {};window.locales.es.student_network = window.locales.es.student_network || {};window.locales.es.student_network.student_network_event_type_filters = window.locales.es.student_network.student_network_event_type_filters || {};window.locales.es.student_network.student_network_event_type_filters = {...window.locales.es.student_network.student_network_event_type_filters, ...{
    "title": "Filtrar por tipo de evento",
    "submit_button_text": "Aplicar"
}
};window.locales.it = window.locales.it || {};window.locales.it.student_network = window.locales.it.student_network || {};window.locales.it.student_network.student_network_event_type_filters = window.locales.it.student_network.student_network_event_type_filters || {};window.locales.it.student_network.student_network_event_type_filters = {...window.locales.it.student_network.student_network_event_type_filters, ...{
    "title": "Filtra per tipo di evento",
    "submit_button_text": "Applicare"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.student_network = window.locales.zh.student_network || {};window.locales.zh.student_network.student_network_event_type_filters = window.locales.zh.student_network.student_network_event_type_filters || {};window.locales.zh.student_network.student_network_event_type_filters = {...window.locales.zh.student_network.student_network_event_type_filters, ...{
    "title": "根据活动类型筛选",           
    "submit_button_text": "应用"           
}
};