window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.student_network = window.locales.am.student_network || {};window.locales.am.student_network.student_network_recommended_event_list = window.locales.am.student_network.student_network_recommended_event_list || {};window.locales.am.student_network.student_network_recommended_event_list = {...window.locales.am.student_network.student_network_recommended_event_list, ...{
    "header_when_filters_closed": "የሚመከሩ ክስተቶች",           
    "header_when_filters_open": "ሁሉንም ክስተቶች ይፈልጉ",           
    "upcoming": "የሚመጣ",           
    "past": "ያለፉ ክስተቶች",           
    "search_all": "ሁሉንም ክስተቶች ይፈልጉ...",           
    "search_short": "ይፈልጉ...",           
    "recommended_tooltip": "ለእርስዎ ቦታ ወይም ተጠኝ ተገቢ የሆኑ ክስተቶች",           
    "cancel": "ሰርዝ",           
    "keyword_search_filter_placeholder": "የክስተት ርዕሶችን፣ መግለጫዎችን፣ ወዘተ ይፈልጉ...",           
    "location_filter_placeholder": "በክስተት ቦታ ይፈልጉ...",           
    "event_type_filter_placeholder": "በክስተት አይነት ይፈልጉ...",           
    "all_event_types": "ሁሉም የክስተት አይነቶች",           
    "featured": "ተለይቶ የቀረበ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.student_network = window.locales.ar.student_network || {};window.locales.ar.student_network.student_network_recommended_event_list = window.locales.ar.student_network.student_network_recommended_event_list || {};window.locales.ar.student_network.student_network_recommended_event_list = {...window.locales.ar.student_network.student_network_recommended_event_list, ...{
    "header_when_filters_closed": "الأحداث الموصى بها",
    "upcoming": "القادمة",
    "past": "الأحداث الماضية",
    "search_all": "بحث في كل الأحداث ...",
    "search_short": "بحث...",
    "recommended_tooltip": "الأحداث ذات الصلة بموقعك أو الفوج",
    "header_when_filters_open": "بحث جميع الأحداث",
    "cancel": "إلغاء",
    "keyword_search_filter_placeholder": "البحث في عناوين الأحداث ، والأوصاف ، إلخ ...",
    "location_filter_placeholder": "البحث عن طريق موقع الحدث ...",
    "event_type_filter_placeholder": "البحث حسب نوع الحدث ...",
    "all_event_types": "جميع أنواع الأحداث",
    "featured": "متميز"
}
};window.locales.en = window.locales.en || {};window.locales.en.student_network = window.locales.en.student_network || {};window.locales.en.student_network.student_network_recommended_event_list = window.locales.en.student_network.student_network_recommended_event_list || {};window.locales.en.student_network.student_network_recommended_event_list = {...window.locales.en.student_network.student_network_recommended_event_list, ...{
    "header_when_filters_closed": "Recommended Events",
    "header_when_filters_open": "Search All Events",
    "upcoming": "Upcoming",
    "past": "Past Events",
    "search_all": "Search all events...",
    "search_short": "Search...",
    "recommended_tooltip": "Events that are relevant to your location or cohort",
    "cancel": "Cancel",
    "keyword_search_filter_placeholder": "Search event titles, descriptions, etc...",
    "location_filter_placeholder": "Search by event location...",
    "event_type_filter_placeholder": "Search by event type...",
    "all_event_types": "All Event Types",
    "featured": "Featured"
}
};window.locales.es = window.locales.es || {};window.locales.es.student_network = window.locales.es.student_network || {};window.locales.es.student_network.student_network_recommended_event_list = window.locales.es.student_network.student_network_recommended_event_list || {};window.locales.es.student_network.student_network_recommended_event_list = {...window.locales.es.student_network.student_network_recommended_event_list, ...{
    "header_when_filters_closed": "Eventos recomendados",
    "upcoming": "Próximo",
    "past": "Eventos pasados",
    "search_all": "Buscar todos los eventos ...",
    "search_short": "Buscar...",
    "recommended_tooltip": "Eventos que son relevantes para su ubicación o cohorte",
    "header_when_filters_open": "Buscar todos los eventos",
    "cancel": "Cancelar",
    "keyword_search_filter_placeholder": "Buscar títulos de eventos, descripciones, etc.",
    "location_filter_placeholder": "Buscar por ubicación del evento ...",
    "event_type_filter_placeholder": "Buscar por tipo de evento ...",
    "all_event_types": "Todos los tipos de eventos",
    "featured": "Presentado"
}
};window.locales.it = window.locales.it || {};window.locales.it.student_network = window.locales.it.student_network || {};window.locales.it.student_network.student_network_recommended_event_list = window.locales.it.student_network.student_network_recommended_event_list || {};window.locales.it.student_network.student_network_recommended_event_list = {...window.locales.it.student_network.student_network_recommended_event_list, ...{
    "header_when_filters_closed": "Eventi consigliati",
    "upcoming": "Prossime",
    "past": "Eventi passati",
    "search_all": "Cerca tutti gli eventi ...",
    "search_short": "Ricerca...",
    "recommended_tooltip": "Eventi rilevanti per la tua posizione o coorte",
    "header_when_filters_open": "Cerca in tutti gli eventi",
    "cancel": "Annulla",
    "keyword_search_filter_placeholder": "Cerca titoli di eventi, descrizioni, ecc ...",
    "location_filter_placeholder": "Cerca per luogo dell'evento ...",
    "event_type_filter_placeholder": "Cerca per tipo di evento ...",
    "all_event_types": "Tutti i tipi di evento",
    "featured": "In primo piano"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.student_network = window.locales.zh.student_network || {};window.locales.zh.student_network.student_network_recommended_event_list = window.locales.zh.student_network.student_network_recommended_event_list || {};window.locales.zh.student_network.student_network_recommended_event_list = {...window.locales.zh.student_network.student_network_recommended_event_list, ...{
    "header_when_filters_closed": "推荐活动",           
    "upcoming": "即将到来",           
    "past": "过去的活动",           
    "search_all": "搜索所有活动...",           
    "search_short": "搜索...",           
    "recommended_tooltip": "与你的地点或同期组相关的活动",           
    "header_when_filters_open": "搜索所有活动",           
    "cancel": "取消",           
    "keyword_search_filter_placeholder": "搜索活动名称、介绍等",           
    "location_filter_placeholder": "根据活动地点进行搜索",           
    "event_type_filter_placeholder": "根据活动类型进行搜索",           
    "all_event_types": "所有活动类型",           
    "featured": "精选"
}
};