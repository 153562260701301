window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.student_network = window.locales.am.student_network || {};window.locales.am.student_network.student_network_event_details = window.locales.am.student_network.student_network_event_details || {};window.locales.am.student_network.student_network_event_details = {...window.locales.am.student_network.student_network_event_details, ...{
    "date_and_time": "ቀን &amp; ጊዜ",           
    "description": "መግለጫ",           
    "read_more": "ተጨማሪ ያንብቡ",           
    "read_less": "ትንሽ ያንብቡ",           
    "location": "ቦታ",           
    "get_directions": "አቅጣጫዎችን ያግኙ",           
    "rsvp": "RSVP<i class=\"far fa-external-link\"></i>",           
    "rsvp_not_required": "RSVP አያስፈልግም",           
    "rsvp_closed": "RSVP ተዘግቷል",           
    "details_are_hidden": "ዝርዝሮች ለአሁኑ ተማሪዎች እና ተማሪዎች ይገኛሉ።",
    "rsvp_coming_soon": "ምላሽ በቅርቡ ይመጣል"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.student_network = window.locales.ar.student_network || {};window.locales.ar.student_network.student_network_event_details = window.locales.ar.student_network.student_network_event_details || {};window.locales.ar.student_network.student_network_event_details = {...window.locales.ar.student_network.student_network_event_details, ...{
    "date_and_time": "التاريخ والوقت",
    "description": "وصف",
    "read_more": "قراءة المزيد",
    "read_less": "أقرأ أقل",
    "location": "موقعك",
    "get_directions": "احصل على الاتجاهات",
    "rsvp": "من فضلك، أرسل رد <i class=\"far fa-external-link\"></i>",
    "rsvp_not_required": "RSVP غير مطلوب",
    "rsvp_closed": "RSVP مغلق",
    "rsvp_coming_soon": "RSVP قريبا",
    "details_are_hidden": "التفاصيل متاحة للطلاب والخريجين الحاليين."
}
};window.locales.en = window.locales.en || {};window.locales.en.student_network = window.locales.en.student_network || {};window.locales.en.student_network.student_network_event_details = window.locales.en.student_network.student_network_event_details || {};window.locales.en.student_network.student_network_event_details = {...window.locales.en.student_network.student_network_event_details, ...{
    "date_and_time": "Date &amp; Time",
    "description": "Description",
    "read_more": "Read More",
    "read_less": "Read Less",
    "location": "Location",
    "get_directions": "Get Directions",
    "rsvp": "RSVP<i class=\"far fa-external-link\"></i>",
    "rsvp_not_required": "RSVP Not Required",
    "rsvp_closed": "RSVP Closed",
    "rsvp_coming_soon": "RSVP Coming Soon",
    "details_are_hidden": "Details are available to current students and alumni."
}
};window.locales.es = window.locales.es || {};window.locales.es.student_network = window.locales.es.student_network || {};window.locales.es.student_network.student_network_event_details = window.locales.es.student_network.student_network_event_details || {};window.locales.es.student_network.student_network_event_details = {...window.locales.es.student_network.student_network_event_details, ...{
    "date_and_time": "Fecha y hora",
    "description": "Descripción",
    "read_more": "Lee mas",
    "read_less": "Leer menos",
    "location": "Ubicación",
    "get_directions": "Obtener las direcciones",
    "rsvp": "RSVP <i class=\"far fa-external-link\"></i>",
    "rsvp_not_required": "RSVP no requerido",
    "rsvp_closed": "RSVP cerrado",
    "rsvp_coming_soon": "RSVP Próximamente",
    "details_are_hidden": "Los detalles están disponibles para los estudiantes actuales y ex alumnos."
}
};window.locales.it = window.locales.it || {};window.locales.it.student_network = window.locales.it.student_network || {};window.locales.it.student_network.student_network_event_details = window.locales.it.student_network.student_network_event_details || {};window.locales.it.student_network.student_network_event_details = {...window.locales.it.student_network.student_network_event_details, ...{
    "date_and_time": "Appuntamento",
    "description": "Descrizione",
    "read_more": "Leggi di più",
    "read_less": "Leggi meno",
    "location": "Posizione",
    "get_directions": "Ottenere direzioni",
    "rsvp": "RSVP <i class=\"far fa-external-link\"></i>",
    "rsvp_not_required": "RSVP non richiesto",
    "rsvp_closed": "RSVP chiuso",
    "rsvp_coming_soon": "RSVP in arrivo a breve",
    "details_are_hidden": "I dettagli sono disponibili per gli studenti e gli ex studenti attuali."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.student_network = window.locales.zh.student_network || {};window.locales.zh.student_network.student_network_event_details = window.locales.zh.student_network.student_network_event_details || {};window.locales.zh.student_network.student_network_event_details = {...window.locales.zh.student_network.student_network_event_details, ...{
    "date_and_time": "日期 &amp; 时间",           
    "description": "说明",           
    "read_more": "阅读更多",           
    "read_less": "收起",           
    "location": "地点",           
    "get_directions": "获得指导",           
    "rsvp": "请回复<i class=\"far fa-external-link\"></i>",           
    "rsvp_not_required": "无需回复",           
    "rsvp_closed": "请回复关闭",           
    "rsvp_coming_soon": "回复即将推出",
    "details_are_hidden": "当前学生和校友可以获得详细信息。"
}
};