window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.student_network = window.locales.am.student_network || {};window.locales.am.student_network.contact_form = window.locales.am.student_network.contact_form || {};window.locales.am.student_network.contact_form = {...window.locales.am.student_network.contact_form, ...{
    "contact": "እውቂያ",           
    "reply_to": "ምላሽ ይስጡ ለ",           
    "subject": "ጉዳዩ",           
    "message": "መልዕክት",           
    "reply_to_placeholder": "your.email@address.com",           
    "subject_placeholder": "[{{brandName}} ትስስር] መልእክት ከ {{sendersName}}",           
    "message_placeholder_mobile_safari": "ሃይ {{recipientsNickname}}፣ እራሴን ለማስተዋወቅ ሌሎችን መድረስ እፈልግ ነበር...",           
    "message_placeholder_local": "ሃይ {{recipientsNickname}}፣ \n\nእኔ አካባቢ እርስዎ እንደሚገኙ አይቼ ነበር እና ሌሎችን ለመድረስ እና እራሴን እንደ {{brandName}} ባልደረባ ተማሪ ለማስተዋወቅ እፈልግ ነበር! ከ...\n\n ጋር ለመገናኘት እወዳለሁ፤ ከሰላምታ ጋር፣ \n{{sendersNickname}}",           
    "message_placeholder_attrs": "ሃይ{{recipientsNickname}},\n\n የእርስዎን መገለጫ ካየሁ በኋላ ለመድረስ ፈልጌ ነበር - የ {{brandName}} ተማሪዎች ከመሆን ባለፈ ጥቂት የጋራ ነገሮች ያሉን ይመስላል! ከ...\n\n ጋር ለመገናኘት እወዳለሁ፤ ከሰላምታ ጋር፣ \n{{sendersNickname}}",           
    "message_placeholder_default": "ሃይ {{recipientsNickname}}፣ \n\nየእርስዎ መገለጫ አጓጊ ይመስላል እና ሌሎችን ለመድረስ እና እራሴን እንደ {{brandName}} ባልደረባ ተማሪ ለማስተዋወቅ እፈልግ ነበር! ከ...\n\n ጋር ለመገናኘት እወዳለሁ፤ ከሰላምታ ጋር፣ \n{{sendersNickname}}",           
    "send_message": "መልዕክት ላክ",           
    "message_sent": "መልእክት ተልኳል"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.student_network = window.locales.ar.student_network || {};window.locales.ar.student_network.contact_form = window.locales.ar.student_network.contact_form || {};window.locales.ar.student_network.contact_form = {...window.locales.ar.student_network.contact_form, ...{
    "contact": "اتصل",
    "reply_to": "الرد على",
    "subject": "موضوع",
    "message": "رسالة",
    "reply_to_placeholder": "your.email@address.com",
    "send_message": "ارسل رسالة",
    "message_sent": "تم الارسال",
    "message_placeholder_mobile_safari": "مرحبًا {{recipientsNickname}} ، أردت التواصل معنا لتقديم نفسي ...",
    "subject_placeholder": "[ {{brandName}} الشبكة] رسالة من {{sendersName}}",
    "message_placeholder_local": "مرحبًا {{recipientsNickname}} ، لقد رأيت أنك تقع في منطقتي وأردت التواصل وتقديم نفسي كزميل {{brandName}} طالب! أرغب في الاتصال بـ ... الأفضل ، {{sendersNickname}}",
    "message_placeholder_attrs": "مرحبًا {{recipientsNickname}} ، أردت التواصل بعد الاطلاع على ملف التعريف الخاص بك - يبدو أن لدينا بعض الأشياء المشتركة فيما عدا كوننا {{brandName}} طلاب! أرغب في الاتصال بـ ... الأفضل ، {{sendersNickname}}",
    "message_placeholder_default": "مرحبًا {{recipientsNickname}} ، يبدو ملفك الشخصي مثيرًا للاهتمام ، وأردت التواصل وتقديم نفسي كزميل {{brandName}} طالب! أرغب في الاتصال بـ ... الأفضل ، {{sendersNickname}}"
}
};window.locales.en = window.locales.en || {};window.locales.en.student_network = window.locales.en.student_network || {};window.locales.en.student_network.contact_form = window.locales.en.student_network.contact_form || {};window.locales.en.student_network.contact_form = {...window.locales.en.student_network.contact_form, ...{
              
    "contact": "Contact",

             
    "reply_to": "Reply To",
    "subject": "Subject",
    "message": "Message",

                   
    "reply_to_placeholder": "your.email@address.com",
    "subject_placeholder": "[{{brandName}} Network] Message from {{sendersName}}",
    "message_placeholder_mobile_safari": "Hi {{recipientsNickname}}, I wanted to reach out to introduce myself...",
    "message_placeholder_local": "Hi {{recipientsNickname}},\n\nI saw that you're located in my area and wanted to reach out and introduce myself as a fellow {{brandName}} student! I’d love to connect to...\n\nBest,\n{{sendersNickname}}",
    "message_placeholder_attrs": "Hi {{recipientsNickname}},\n\nI wanted to reach out after seeing your profile – it looks like we have a few things in common beyond being {{brandName}} students! I’d love to connect to...\n\nBest,\n{{sendersNickname}}",
    "message_placeholder_default": "Hi {{recipientsNickname}},\n\nYour profile looks interesting, and I wanted to reach out and introduce myself as a fellow {{brandName}} student! I’d love to connect to...\n\nBest,\n{{sendersNickname}}",

             
    "send_message": "Send Message",

               
    "message_sent": "Message Sent"
}
};window.locales.es = window.locales.es || {};window.locales.es.student_network = window.locales.es.student_network || {};window.locales.es.student_network.contact_form = window.locales.es.student_network.contact_form || {};window.locales.es.student_network.contact_form = {...window.locales.es.student_network.contact_form, ...{
    "contact": "Contacto",
    "reply_to": "Responder a",
    "subject": "Tema",
    "message": "Mensaje",
    "reply_to_placeholder": "your.email@address.com",
    "send_message": "Enviar mensaje",
    "message_sent": "Mensaje enviado",
    "message_placeholder_mobile_safari": "Hola, {{recipientsNickname}} , quería acercarme para presentarme ...",
    "subject_placeholder": "[ {{brandName}} Network] Mensaje de {{sendersName}}",
    "message_placeholder_local": "Hola {{recipientsNickname}} , ¡vi que te encuentras en mi área y quería comunicarte y presentarme como un compañero {{brandName}} estudiante! Me encantaría conectarme a ... Mejor, {{sendersNickname}}",
    "message_placeholder_attrs": "Hola, {{recipientsNickname}} : quería comunicarme después de ver tu perfil. ¡Parece que tenemos algunas cosas en común además de ser {{brandName}} estudiantes! Me encantaría conectarme a ... Mejor, {{sendersNickname}}",
    "message_placeholder_default": "Hola, {{recipientsNickname}} : ¡Tu perfil se ve interesante y quería comunicarme y presentarme como un compañero {{brandName}} estudiante! Me encantaría conectarme a ... Mejor, {{sendersNickname}}"
}
};window.locales.it = window.locales.it || {};window.locales.it.student_network = window.locales.it.student_network || {};window.locales.it.student_network.contact_form = window.locales.it.student_network.contact_form || {};window.locales.it.student_network.contact_form = {...window.locales.it.student_network.contact_form, ...{
    "contact": "Contatto",
    "reply_to": "Rispondi a",
    "subject": "Soggetto",
    "message": "Messaggio",
    "reply_to_placeholder": "your.email@address.com",
    "send_message": "Invia messaggio",
    "message_sent": "Messaggio inviato",
    "message_placeholder_mobile_safari": "Ciao {{recipientsNickname}} , volevo raggiungere me stesso per presentarmi ...",
    "subject_placeholder": "[ {{brandName}} Rete] Messaggio da {{sendersName}}",
    "message_placeholder_local": "Ciao {{recipientsNickname}} , ho visto che ti trovi nella mia zona e volevo {{brandName}} e presentarmi come studente {{brandName}} ! Mi piacerebbe connettermi a ... Best, {{sendersNickname}}",
    "message_placeholder_attrs": "Ciao {{recipientsNickname}} , volevo {{brandName}} in contatto dopo aver visto il tuo profilo - sembra che abbiamo alcune cose in comune oltre a essere {{brandName}} studenti! Mi piacerebbe connettermi a ... Best, {{sendersNickname}}",
    "message_placeholder_default": "Ciao {{recipientsNickname}} , il tuo profilo sembra interessante e volevo {{brandName}} e presentarmi come studente {{brandName}} ! Mi piacerebbe connettermi a ... Best, {{sendersNickname}}"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.student_network = window.locales.zh.student_network || {};window.locales.zh.student_network.contact_form = window.locales.zh.student_network.contact_form || {};window.locales.zh.student_network.contact_form = {...window.locales.zh.student_network.contact_form, ...{
    "contact": "联系",           
    "reply_to": "回复",           
    "subject": "主题",           
    "message": "消息",           
    "reply_to_placeholder": "your.email@address.com",           
    "send_message": "发送消息",           
    "message_sent": "消息已发送",           
    "message_placeholder_mobile_safari": "你好，{{recipientsNickname}}，我想进行一下自我介绍...",           
    "subject_placeholder": "来自{{sendersName}}的[{{brandName}} 网络]消息",           
    "message_placeholder_local": "你好，{{recipientsNickname}}，\r\n\r\n我发现我们同处一个地区，我也是{{brandName}}学员，我想进行一下自我介绍！我想联系...\r\n\r\n谨启，\r\n{{sendersNickname}}",           
    "message_placeholder_attrs": "你好，{{recipientsNickname}}，\r\n\r\n看到你的个人资料，发现我们都是{{brandName}}的学员，而且我们还有一些其他共同点，想与你进行联系！我想联系...\r\n\r\n谨启，\r\n{{sendersNickname}}",           
    "message_placeholder_default": "你好，{{recipientsNickname}}，\r\n\r\n我对你的资料很感兴趣，我也是{{brandName}}的学员，我想进行一下自我介绍！我想联系...\r\n\r\n谨启，\r\n{{sendersNickname}}"           
}
};