window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.student_network = window.locales.am.student_network || {};window.locales.am.student_network.student_profile_list = window.locales.am.student_network.student_profile_list || {};window.locales.am.student_network.student_profile_list = {...window.locales.am.student_network.student_profile_list, ...{
    "contact": "እውቂያ",           
    "interests": "ፍላጎቶች",           
    "looking_for": "እየፈለኩ ያለሁት",           
    "linkedin": "ሊንከዲን",           
    "github": "Github",           
    "students_near": "በቅርብ ያሉ ተማሪዎች",           
    "remove_filter": "ማጣሪያን ያስወግዱ",           
    "no_students_found": "ምንም ተማሪዎች አልተገኙም",           
    "try_adjusting_your_filters": "የእርስዎን ማጣሪያዎች ለማስተካከል ይሞክሩ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.student_network = window.locales.ar.student_network || {};window.locales.ar.student_network.student_profile_list = window.locales.ar.student_network.student_profile_list || {};window.locales.ar.student_network.student_profile_list = {...window.locales.ar.student_network.student_profile_list, ...{
    "contact": "اتصل",
    "interests": "الإهتمامات",
    "looking_for": "البحث عن",
    "linkedin": "ينكدين",
    "github": "جيثب",
    "students_near": "الطلاب بالقرب",
    "remove_filter": "إزالة مرشح",
    "no_students_found": "لم يتم العثور على الطلاب",
    "try_adjusting_your_filters": "حاول ضبط الفلاتر"
}
};window.locales.en = window.locales.en || {};window.locales.en.student_network = window.locales.en.student_network || {};window.locales.en.student_network.student_profile_list = window.locales.en.student_network.student_profile_list || {};window.locales.en.student_network.student_profile_list = {...window.locales.en.student_network.student_profile_list, ...{
    "contact": "Contact",
    "interests": "Interests",
    "looking_for": "Looking For",
    "linkedin": "LinkedIn",
    "github": "Github",
    "students_near": "Students Near",
    "remove_filter": "Remove Filter",
    "no_students_found": "No Students Found",
    "try_adjusting_your_filters": "Try adjusting your filters"
}
};window.locales.es = window.locales.es || {};window.locales.es.student_network = window.locales.es.student_network || {};window.locales.es.student_network.student_profile_list = window.locales.es.student_network.student_profile_list || {};window.locales.es.student_network.student_profile_list = {...window.locales.es.student_network.student_profile_list, ...{
    "contact": "Contacto",
    "interests": "Intereses",
    "looking_for": "Buscando",
    "linkedin": "LinkedIn",
    "github": "Github",
    "students_near": "Estudiantes cerca",
    "remove_filter": "Eliminar filtro",
    "no_students_found": "No se encontraron estudiantes",
    "try_adjusting_your_filters": "Intenta ajustar tus filtros"
}
};window.locales.it = window.locales.it || {};window.locales.it.student_network = window.locales.it.student_network || {};window.locales.it.student_network.student_profile_list = window.locales.it.student_network.student_profile_list || {};window.locales.it.student_network.student_profile_list = {...window.locales.it.student_network.student_profile_list, ...{
    "contact": "Contatto",
    "interests": "Interessi",
    "looking_for": "Cercando",
    "linkedin": "LinkedIn",
    "github": "Github",
    "students_near": "Studenti vicino",
    "remove_filter": "Rimuovi filtro",
    "no_students_found": "Nessun studente trovato",
    "try_adjusting_your_filters": "Prova a regolare i filtri"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.student_network = window.locales.zh.student_network || {};window.locales.zh.student_network.student_profile_list = window.locales.zh.student_network.student_profile_list || {};window.locales.zh.student_network.student_profile_list = {...window.locales.zh.student_network.student_profile_list, ...{
    "contact": "联系",           
    "interests": "兴趣",           
    "looking_for": "寻找",           
    "linkedin": "领英",           
    "github": "Github",           
    "students_near": "附近的学员",           
    "remove_filter": "删除筛选器",           
    "no_students_found": "未发现学员",           
    "try_adjusting_your_filters": "尝试调整你的筛选器"           
}
};