window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.student_network = window.locales.am.student_network || {};window.locales.am.student_network.onboarding_dialog_modal = window.locales.am.student_network.onboarding_dialog_modal || {};window.locales.am.student_network.onboarding_dialog_modal = {...window.locales.am.student_network.onboarding_dialog_modal, ...{
    "step_one_title": "ወደ ግንኙነቱ እንኳን በደህና መጡ",           
    "step_one_message": "የ {{brandName}}ልዩ አለም አቀፍ አውታረመረብን ያግኙ። ለማሰስ ካርታዉን እና ማጣሪያውን ይጠቀሙ።",           
    "step_two_title": "ማህበረሰቡን ያስሱ",           
    "step_two_message": "ክቦች የሚወክሉት የተማሪዎች እና የቀድሞ ተማሪዎች ክምችትን ነው። በዛ ክልል ውስጥ ያሉትን መገለጫዎች ለማየት ጠቅ ያድርጉ።",           
    "step_three_title": "በመተማመን ይገናኙ",           
    "step_three_message": "ለክፍል ልጆች መልእክት በመላክ ትርጉም ያለው ግንኙነቶችን ያድርጉ።",           
    "step_four_title": "የሚያነሳሱ ክስተቶችን ያግኙ",           
    "step_four_message": "በመስመር ላይ እና በአለም ዙሪያ የሚዘጋጁትን <br>የእኛን ብቸኛ፣ የቀጥታ ክስተቶች ያስሱ።",           
    "anonymous": "ስም አልባ",           
    "hidden": "የተደበቀ",           
    "activation_title": "የራስዎን መገለጫ ያንቁ",           
    "activation_message": "የእርስዎ የተማሪ አውታረመረብ መገለጫ በአሁኑ ጊዜ በ <b>{{setting}}</b> ተዘጋጅቷል። የእርስዎ መገለጫ ለጓደኞችዎ እንዲታይ ለማድረግ ይፈልጋሉ?",           
    "change_setting_smallprint": "የእርስዎን ምርጫዎች በማንኛዉም ጊዜ በ <a ng-click=\"loadRoute('/settings/my-profile?page={{pageNumber}}')\">የአውታረመረብ ቅንብሮች</a>ውስጥ መቀየር ይችላሉ።",           
    "email_title": "ተመራጭ የማግኛ ኢሜይል",           
    "email_message": "አንድ ተጨማሪ ነገር ብቻ - ከተማሪዎች እና ከቀድሞ ተማሪዎች መልእክቶችን ለማግኘት የሚፈልጉበትን የኢሜይል አድራሻ እባክዎ ያረጋገጡ!",           
    "email_address": "የኢሜይል አድራሻ",           
    "next": "ቀጣይ",           
    "get_started": "አሁን ጀምር",           
    "activate_my_profile": "አዎ! የእኔን መገለጫ ያንቁ",           
    "stay_setting": "አይ፣ ይቆዩ {{setting}}",           
    "complete_setup": "ዝግጅቱን ያጠናቅቁ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.student_network = window.locales.ar.student_network || {};window.locales.ar.student_network.onboarding_dialog_modal = window.locales.ar.student_network.onboarding_dialog_modal || {};window.locales.ar.student_network.onboarding_dialog_modal = {...window.locales.ar.student_network.onboarding_dialog_modal, ...{
    "step_one_title": "مرحبًا بك في الشبكة",
    "step_two_title": "انتقل المجتمع",
    "step_two_message": "تمثل الدوائر مجموعات من الطلاب والخريجين. انقر لعرض الملفات الشخصية في تلك المنطقة.",
    "step_three_title": "تواصل مع الثقة",
    "step_three_message": "جعل اتصالات ذات مغزى عن طريق الرسائل زملاء الدراسة.",
    "activation_title": "تفعيل ملفك الشخصي",
    "change_setting_smallprint": "يمكنك تغيير هذا التفضيل في أي وقت في <a ng-click=\"loadRoute('/settings/my-profile?page={{pageNumber}}')\">إعدادات الشبكة</a> .",
    "next": "التالى",
    "get_started": "البدء",
    "activate_my_profile": "نعم فعلا! تفعيل ملفي الشخصي",
    "anonymous": "مجهول",
    "hidden": "مخفي",
    "activation_message": "تم تعيين الملف الشخصي على شبكة الطالب حاليًا على <b>{{setting}}</b> . هل ترغب في جعل ملفك الشخصي مرئيًا لزملائك؟",
    "stay_setting": "لا ، ابقى {{setting}}",
    "step_four_title": "اكتشاف الأحداث الملهمة",
    "step_four_message": "تصفح الأحداث الحصرية الحية الخاصة بنا <br> استضافت على الانترنت وحول العالم.",
    "step_one_message": "اكتشف {{brandName}} الشبكة العالمية الحصرية. استخدم الخريطة والفلاتر لاستكشافها.",
    "email_title": "البريد الإلكتروني المفضل للاتصال",
    "email_message": "شيء واحد فقط - يرجى تأكيد عنوان البريد الإلكتروني الذي ترغب في تلقي رسائل من الطلاب والخريجين!",
    "email_address": "عنوان البريد الإلكتروني",
    "complete_setup": "أكمل الإعداد"
}
};window.locales.en = window.locales.en || {};window.locales.en.student_network = window.locales.en.student_network || {};window.locales.en.student_network.onboarding_dialog_modal = window.locales.en.student_network.onboarding_dialog_modal || {};window.locales.en.student_network.onboarding_dialog_modal = {...window.locales.en.student_network.onboarding_dialog_modal, ...{
               
    "step_one_title": "Welcome to the Network",
    "step_one_message": "Discover {{brandName}}'s exclusive global network. Use the map and filters to explore.",

               
    "step_two_title": "Navigate the Community",
    "step_two_message": "Circles represent clusters of students and alumni. Click to view profiles in that region.",

                 
    "step_three_title": "Connect with Confidence",
    "step_three_message": "Make meaningful connections by messaging classmates.",

                
    "step_four_title": "Discover Inspiring Events",
    "step_four_message": "Browse our exclusive, live events, <br>hosted online and around the world.",

                       
    "anonymous": "anonymous",
    "hidden": "hidden",

                 
    "activation_title": "Activate your Profile",
    "activation_message": "Your student network profile is currently set to <b>{{setting}}</b>. Would you like to make your profile visible to your peers?",
    "change_setting_smallprint": "You can change this preference at any time in <a ng-click=\"loadRoute('/settings/my-profile?page={{pageNumber}}')\">network settings</a>.",

            
    "email_title": "Preferred Contact Email",
    "email_message": "Just one more thing – please confirm the email address you'd like to receive messages from students and alumni!",
    "email_address": "Email Address",

              
    "next": "Next",
    "get_started": "Get Started",
    "activate_my_profile": "Yes! Activate my profile",
    "stay_setting": "No, stay {{setting}}",
    "complete_setup": "Complete Setup"
}
};window.locales.es = window.locales.es || {};window.locales.es.student_network = window.locales.es.student_network || {};window.locales.es.student_network.onboarding_dialog_modal = window.locales.es.student_network.onboarding_dialog_modal || {};window.locales.es.student_network.onboarding_dialog_modal = {...window.locales.es.student_network.onboarding_dialog_modal, ...{
    "step_one_title": "Bienvenido a la red",
    "step_two_title": "Navegue por la comunidad",
    "step_two_message": "Los círculos representan grupos de estudiantes y ex alumnos. Haga clic para ver los perfiles en esa región.",
    "step_three_title": "Conéctate con confianza",
    "step_three_message": "Haga conexiones significativas con los compañeros de clase de mensajería.",
    "activation_title": "Activa tu perfil",
    "change_setting_smallprint": "Puede cambiar esta preferencia en cualquier momento en <a ng-click=\"loadRoute('/settings/my-profile?page={{pageNumber}}')\">la configuración de red</a> .",
    "next": "Siguiente",
    "get_started": "Empezar",
    "activate_my_profile": "¡Sí! Activa mi perfil",
    "anonymous": "anónimo",
    "hidden": "oculto",
    "activation_message": "El perfil de red de su alumno está configurado actualmente en <b>{{setting}}</b> . ¿Le gustaría hacer que su perfil sea visible para sus pares?",
    "stay_setting": "No, quédate {{setting}}",
    "step_four_title": "Descubre eventos inspiradores",
    "step_four_message": "Explore nuestros exclusivos eventos en vivo, <br> alojado en línea y en todo el mundo.",
    "step_one_message": "Descubra la red global exclusiva de {{brandName}} . Usa el mapa y los filtros para explorar.",
    "email_title": "Correo electrónico de contacto preferido",
    "email_message": "Solo una cosa más: ¡confirme la dirección de correo electrónico en la que desea recibir los mensajes de estudiantes y ex alumnos!",
    "email_address": "Dirección de correo electrónico",
    "complete_setup": "Configuración completa"
}
};window.locales.it = window.locales.it || {};window.locales.it.student_network = window.locales.it.student_network || {};window.locales.it.student_network.onboarding_dialog_modal = window.locales.it.student_network.onboarding_dialog_modal || {};window.locales.it.student_network.onboarding_dialog_modal = {...window.locales.it.student_network.onboarding_dialog_modal, ...{
    "step_one_title": "Benvenuti in rete",
    "step_two_title": "Naviga nella community",
    "step_two_message": "I cerchi rappresentano gruppi di studenti e alunni. Clicca per vedere i profili in quella regione.",
    "step_three_title": "Connettiti con fiducia",
    "step_three_message": "Crea connessioni significative tramite i compagni di classe di messaggistica.",
    "activation_title": "Attiva il tuo profilo",
    "change_setting_smallprint": "È possibile modificare questa preferenza in qualsiasi momento nelle <a ng-click=\"loadRoute('/settings/my-profile?page={{pageNumber}}')\">impostazioni di rete</a> .",
    "next": "Il prossimo",
    "get_started": "Iniziare",
    "activate_my_profile": "Sì! Attiva il mio profilo",
    "anonymous": "anonimo",
    "hidden": "nascosto",
    "activation_message": "Il tuo profilo di rete per studenti è attualmente impostato su <b>{{setting}}</b> . Vorresti rendere il tuo profilo visibile ai tuoi colleghi?",
    "stay_setting": "No, resta {{setting}}",
    "step_four_title": "Scopri eventi stimolanti",
    "step_four_message": "Sfoglia i nostri eventi esclusivi dal vivo, <br> ospitato online e in tutto il mondo.",
    "step_one_message": "Scopri l'esclusiva rete globale di {{brandName}} . Usa la mappa e i filtri per esplorare.",
    "email_title": "Email di contatto preferita",
    "email_message": "Ancora un'altra cosa: conferma l'indirizzo email che desideri ricevere messaggi da studenti e ex studenti!",
    "email_address": "Indirizzo email",
    "complete_setup": "Installazione completa"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.student_network = window.locales.zh.student_network || {};window.locales.zh.student_network.onboarding_dialog_modal = window.locales.zh.student_network.onboarding_dialog_modal || {};window.locales.zh.student_network.onboarding_dialog_modal = {...window.locales.zh.student_network.onboarding_dialog_modal, ...{
    "step_one_title": "欢迎加入网络",           
    "step_two_title": "导航到社区",           
    "step_two_message": "圆圈代表学员和校友群体。单击可查看该区域的人员资料。",           
    "step_three_title": "自信联系",           
    "step_three_message": "向同学发送信息，建立有效联系。",           
    "activation_title": "激活你的资料",           
    "change_setting_smallprint": "你可在<a ng-click=\"loadRoute('/settings/my-profile?page={{pageNumber}}')\">网络设置</a>中随时改变该设置。",           
    "next": "后一个",           
    "get_started": "开始",           
    "activate_my_profile": "正确！激活我的资料",           
    "anonymous": "匿名",           
    "hidden": "已隐藏",           
    "activation_message": "您的学员网络资料当前设置为<b>{{setting}}</b>。你想将自己的个人资料设置为同伴可见吗？",           
    "stay_setting": "不，保持{{setting}}",           
    "step_four_title": "探索卓越活动",           
    "step_four_message": "浏览我们<br>在线和全球举办的独家现场活动。",           
    "step_one_message": "探索{{brandName}}的独家全球网络。使用地图和筛选器进行探索。",           
    "email_title": "首选联系邮箱",           
    "email_message": "还有一点，请确认你想从学员和校友那里接收信息的电子邮箱！",           
    "email_address": "电子邮箱",           
    "complete_setup": "完成设置"           
}
};