window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.student_network = window.locales.am.student_network || {};window.locales.am.student_network.student_network_event_list_item = window.locales.am.student_network.student_network_event_list_item || {};window.locales.am.student_network.student_network_event_list_item = {...window.locales.am.student_network.student_network_event_list_item, ...{
    "view_event": "ይመልከቱ",           
    "view_event_tooltip_details_unavailable": "የክስተት ዝርዝሮች ለ MBA እና ለዋና MBA ተማሪዎች እና የቀድሞ ተማሪዎች ብቻ ይገኛል።"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.student_network = window.locales.ar.student_network || {};window.locales.ar.student_network.student_network_event_list_item = window.locales.ar.student_network.student_network_event_list_item || {};window.locales.ar.student_network.student_network_event_list_item = {...window.locales.ar.student_network.student_network_event_list_item, ...{
    "view_event": "رأي",
    "view_event_tooltip_details_unavailable": "تفاصيل الحدث متاحة فقط لطلاب ماجستير إدارة الأعمال والماجستير في إدارة الأعمال التنفيذية والخريجين."
}
};window.locales.en = window.locales.en || {};window.locales.en.student_network = window.locales.en.student_network || {};window.locales.en.student_network.student_network_event_list_item = window.locales.en.student_network.student_network_event_list_item || {};window.locales.en.student_network.student_network_event_list_item = {...window.locales.en.student_network.student_network_event_list_item, ...{
    "view_event": "View",
    "view_event_tooltip_details_unavailable": "Event details are only available to MBA and Executive MBA students and alumni."
}
};window.locales.es = window.locales.es || {};window.locales.es.student_network = window.locales.es.student_network || {};window.locales.es.student_network.student_network_event_list_item = window.locales.es.student_network.student_network_event_list_item || {};window.locales.es.student_network.student_network_event_list_item = {...window.locales.es.student_network.student_network_event_list_item, ...{
    "view_event": "Ver",
    "view_event_tooltip_details_unavailable": "Los detalles del evento solo están disponibles para estudiantes y ex alumnos de MBA y Executive MBA."
}
};window.locales.it = window.locales.it || {};window.locales.it.student_network = window.locales.it.student_network || {};window.locales.it.student_network.student_network_event_list_item = window.locales.it.student_network.student_network_event_list_item || {};window.locales.it.student_network.student_network_event_list_item = {...window.locales.it.student_network.student_network_event_list_item, ...{
    "view_event": "vista",
    "view_event_tooltip_details_unavailable": "I dettagli dell'evento sono disponibili solo per studenti e ex studenti MBA ed Executive MBA."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.student_network = window.locales.zh.student_network || {};window.locales.zh.student_network.student_network_event_list_item = window.locales.zh.student_network.student_network_event_list_item || {};window.locales.zh.student_network.student_network_event_list_item = {...window.locales.zh.student_network.student_network_event_list_item, ...{
    "view_event": "查看",           
    "view_event_tooltip_details_unavailable": "活动详情仅对工商管理硕士（MBA）和高级工商管理硕士（EMBA）学员及校友开放。"           
}
};