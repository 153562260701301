window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.student_network = window.locales.am.student_network || {};window.locales.am.student_network.student_network = window.locales.am.student_network.student_network || {};window.locales.am.student_network.student_network = {...window.locales.am.student_network.student_network, ...{
    "network_title": "አውታረመረብ",           
    "what_im_looking_for": "የምፈልገው",           
    "looking_for_desc": "ከግንኙነቱ የሆነ የተወሰነ የሚፈልጉት ነገር አለ? የሚተገበረውን በሙሉ ይምረጡ።",           
    "interests": "የእኔ ፍላጎቶች",           
    "interests_desc": "የእርስዎን ፍላጎቶች፣ ክህሎቶች፣ እና ስሜቶች የሚጋሩ ሌሎች ተማሪዎችን ያግኙ። ከስር እስከ 12 ድረስ ያክሉ።",           
    "add_an_interest": "ፍላጎት ያክሉ",           
    "privacy_settings": "የግላዊነት ቅንብሮች",           
    "privacy_desc": "የእርስዎ መገለጫ ለባልደረባ ተማሪዎች እንዴት መታየት እንደሚፈልጉ ይምረጡ።",           
    "select_one": "አንድ ይምረጡ",           
    "save_network_settings": "የቅንኙነት ቅንብሮችን ያስቀምጡ",           
    "saved_settings_success": "ቅንብሮች በተሳካ ሁኔታ ተቀምጠው ነበር።",           
    "your_profile_will_be_visible": "የእርስዎ መገለጫ ለባልደረባ ተማሪዎች ይታያል።<br class=\"hidden-sm\">የወደፊት ተማሪዎች ስም አልባ መገለጫዎችን ብቻ ያያሉ።",           
    "preferred_email": "ተመራጭ ኢሜይል",           
    "preferred_email_desc": "ፍላጎት ላላቸው አካላት እርስዎን እንዲያገኙ የኢሜይል አድራሻ ያቅርቡ።",           
    "preferred_email_placeholder": "የኢሜይል አድራሻ",           
    "not_yet_available": "በእርስዎ ክልል ውስጥ የአውታረመረብ ካርታ<br /> እስካሁን አይገኝም።"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.student_network = window.locales.ar.student_network || {};window.locales.ar.student_network.student_network = window.locales.ar.student_network.student_network || {};window.locales.ar.student_network.student_network = {...window.locales.ar.student_network.student_network, ...{
    "network_title": "شبكة الاتصال",
    "add_an_interest": "أضف مصلحة",
    "what_im_looking_for": "ما أبحث عنه",
    "looking_for_desc": "هل تبحث عن شيء محدد من الشبكة؟ اختر كل ما ينطبق.",
    "interests": "اهتماماتي",
    "interests_desc": "اعثر على طلاب آخرين يشاركونك اهتماماتك أو مهاراتك أو عواطفك. أضف ما يصل إلى 12 أدناه.",
    "privacy_settings": "إعدادات الخصوصية",
    "privacy_desc": "حدد كيف تريد أن ينظر ملفك الشخصي إلى زملائه الطلاب.",
    "select_one": "اختر واحدة",
    "save_network_settings": "احفظ إعدادات الشبكة",
    "saved_settings_success": "تم حفظ الإعدادات بنجاح.",
    "your_profile_will_be_visible": "سيكون ملفك الشخصي مرئيًا لزملائه من الطلاب. <br class=\"hidden-sm\"> سيرى الطلاب المحتملون ملفات تعريف مجهولة فقط.",
    "preferred_email": "البريد الإلكتروني المفضل",
    "preferred_email_desc": "قم بتوفير عنوان بريد إلكتروني للأطراف المهتمة للاتصال بك.",
    "preferred_email_placeholder": "عنوان البريد الإلكتروني",
    "not_yet_available": "خريطة الشبكة ليست بعد<br /> متاح في منطقتك."
}
};window.locales.en = window.locales.en || {};window.locales.en.student_network = window.locales.en.student_network || {};window.locales.en.student_network.student_network = window.locales.en.student_network.student_network || {};window.locales.en.student_network.student_network = {...window.locales.en.student_network.student_network, ...{
    "network_title": "NETWORK",

               
    "what_im_looking_for": "What I'm Looking For",
    "looking_for_desc": "Looking for something specific from the network? Select all that apply.",
    "interests": "My Interests",
    "interests_desc": "Find other students who share your interests, skills, or passions. Add up to 12 below.",
    "add_an_interest": "Add an interest",
    "privacy_settings": "Privacy Settings",
    "privacy_desc": "Select how you want your profile to look to fellow students.",
    "select_one": "Select One",
    "save_network_settings": "Save Network Settings",
    "saved_settings_success": "Settings were successfully saved.",
    "your_profile_will_be_visible": "Your profile will be visible to fellow students.<br class=\"hidden-sm\">Prospective students will see anonymous profiles only.",
    "preferred_email": "Preferred Email",
    "preferred_email_desc": "Supply an email address for interested parties to contact you.",
    "preferred_email_placeholder": "Email Address",
    "not_yet_available": "Network map is not yet<br /> available in your region."
}
};window.locales.es = window.locales.es || {};window.locales.es.student_network = window.locales.es.student_network || {};window.locales.es.student_network.student_network = window.locales.es.student_network.student_network || {};window.locales.es.student_network.student_network = {...window.locales.es.student_network.student_network, ...{
    "network_title": "RED",
    "add_an_interest": "Añadir un interés",
    "what_im_looking_for": "Lo que estoy buscando",
    "looking_for_desc": "¿Buscando algo específico de la red? Seleccione todas las que correspondan.",
    "interests": "Mis intereses",
    "interests_desc": "Encuentre otros estudiantes que compartan sus intereses, habilidades o pasiones. Agregue hasta 12 a continuación.",
    "privacy_settings": "La configuración de privacidad",
    "privacy_desc": "Seleccione cómo desea que su perfil se vea a sus compañeros.",
    "select_one": "Seleccione uno",
    "save_network_settings": "Guardar configuración de red",
    "saved_settings_success": "La configuración se guardó con éxito.",
    "your_profile_will_be_visible": "Su perfil será visible para sus compañeros estudiantes. <br class=\"hidden-sm\"> Los futuros estudiantes verán solo perfiles anónimos.",
    "preferred_email": "Email preferido",
    "preferred_email_desc": "Proporcione una dirección de correo electrónico para que las partes interesadas se comuniquen con usted.",
    "preferred_email_placeholder": "Dirección de correo electrónico",
    "not_yet_available": "El mapa de red aún no está<br /> disponible en su región."
}
};window.locales.it = window.locales.it || {};window.locales.it.student_network = window.locales.it.student_network || {};window.locales.it.student_network.student_network = window.locales.it.student_network.student_network || {};window.locales.it.student_network.student_network = {...window.locales.it.student_network.student_network, ...{
    "network_title": "RETE",
    "add_an_interest": "Aggiungi un interesse",
    "what_im_looking_for": "Cosa sto cercando",
    "looking_for_desc": "Stai cercando qualcosa di specifico dalla rete? Seleziona tutto ciò che si applica.",
    "interests": "I miei interessi",
    "interests_desc": "Trova altri studenti che condividono i tuoi interessi, abilità o passioni. Aggiungi fino a 12 di seguito.",
    "privacy_settings": "Impostazioni della privacy",
    "privacy_desc": "Seleziona come vuoi che il tuo profilo guardi agli altri studenti.",
    "select_one": "Seleziona uno",
    "save_network_settings": "Salva le impostazioni di rete",
    "saved_settings_success": "Le impostazioni sono state salvate correttamente.",
    "your_profile_will_be_visible": "Il tuo profilo sarà visibile agli altri studenti. <br class=\"hidden-sm\"> I potenziali studenti vedranno solo i profili anonimi.",
    "preferred_email": "Email preferita",
    "preferred_email_desc": "Fornire un indirizzo e-mail affinché le parti interessate possano contattarvi.",
    "preferred_email_placeholder": "Indirizzo email",
    "not_yet_available": "La mappa di rete non è ancora disponibile<br /> disponibile nella tua regione."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.student_network = window.locales.zh.student_network || {};window.locales.zh.student_network.student_network = window.locales.zh.student_network.student_network || {};window.locales.zh.student_network.student_network = {...window.locales.zh.student_network.student_network, ...{
    "network_title": "网络",           
    "add_an_interest": "添加一项兴趣",           
    "what_im_looking_for": "我的目标",           
    "looking_for_desc": "想从网络中探寻某些具体目标？选择所有适用选项。",           
    "interests": "我的兴趣",           
    "interests_desc": "找到和你拥有共同兴趣、技能或激情的同学。在下面添加到12。",           
    "privacy_settings": "隐私设置",           
    "privacy_desc": "选择你个人资料的展示方式。",           
    "select_one": "选择一种",           
    "save_network_settings": "保存网络设置",           
    "saved_settings_success": "设置已成功保存。",           
    "your_profile_will_be_visible": "你的个人资料将对同学可见。<br class=\"hidden-sm\">未来的学员只会看到匿名的资料。",           
    "preferred_email": "首选邮箱",           
    "preferred_email_desc": "请提供一个邮箱地址，以便有兴趣的人士与你联系。",           
    "preferred_email_placeholder": "电子邮箱",           
    "not_yet_available": "你所在地区的网络地图尚不<br />可用。"           
}
};