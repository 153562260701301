window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.student_network = window.locales.am.student_network || {};window.locales.am.student_network.field_options = window.locales.am.student_network.field_options || {};window.locales.am.student_network.field_options = {...window.locales.am.student_network.field_options, ...{
    "co_founder": "የጋራ መስራች",           
    "study_partner": "የጥናት አጋር",           
    "collaborators_talent": "ተባባሪዎች እና ለመቅጠር የሚሆን ሰው",           
    "funding": "የገንዘብ ድጋፍ",           
    "ideas_inspiration": "ሃሳቦች እና መነሳሳት",           
    "job_opportunities": "የስራ እድሎች",           
    "mentorship": "መካሪነት",           
    "new_friends": "አዳዲስ ጓደኞች",           
    "full": "መደበኛ (የሚመከር)",           
    "anonymous": "ስም አልባ",           
    "hidden": "የተደበቀ",           
    "popular_interests": "ታዋቂ ፍላጎቶች",           
    "travel": "ጉዞ",           
    "finance": "ፋይናንስ",           
    "entrepreneurship": "ስራ ፈጣሪነት",           
    "art": "ጥበብ",           
    "education": "ትምህርት",           
    "marketing": "ማርኬቲንግ",           
    "health_care": "የጤና ጥበቃ",           
    "nonprofit": "ለትርፍ ያልቆመ",           
    "sports": "ስፖርቶች",           
    "technology": "ቴክኖሎጂ",           
    "data_science": "የውሂብ ሳይንስ",           
    "blockchain": "ብሎክቼይን",           
    "product_management": "የምርት አስተዳደር",           
    "design": "ንድፍ",           
    "sales": "ሽያጭ",           
    "leadership": "አመራር",           
    "mixology": "ሚክሶሎጂ",           
    "ai": "AI"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.student_network = window.locales.ar.student_network || {};window.locales.ar.student_network.field_options = window.locales.ar.student_network.field_options || {};window.locales.ar.student_network.field_options = {...window.locales.ar.student_network.field_options, ...{
    "co_founder": "شريك مؤسس",
    "study_partner": "شريك الدراسة",
    "collaborators_talent": "المتعاونون والمواهب لتوظيف",
    "funding": "التمويل",
    "ideas_inspiration": "الأفكار والإلهام",
    "job_opportunities": "فرص عمل",
    "mentorship": "الإرشاد",
    "new_friends": "اصدقاء جدد",
    "full": "قياسي (موصى به)",
    "anonymous": "مجهول",
    "hidden": "مخفي",
    "popular_interests": "الاهتمامات الشعبية",
    "travel": "السفر",
    "finance": "المالية",
    "entrepreneurship": "ريادة الأعمال",
    "art": "فن",
    "education": "التعليم",
    "marketing": "تسويق",
    "health_care": "الرعاىة الصحية",
    "nonprofit": "غير ربحية",
    "sports": "رياضات",
    "technology": "تقنية",
    "data_science": "علوم البيانات",
    "blockchain": "كتلة سلسلة",
    "product_management": "ادارة المنتج",
    "design": "التصميم",
    "sales": "مبيعات",
    "leadership": "قيادة",
    "mixology": "Mixology",
    "ai": "منظمة العفو الدولية"
}
};window.locales.en = window.locales.en || {};window.locales.en.student_network = window.locales.en.student_network || {};window.locales.en.student_network.field_options = window.locales.en.student_network.field_options || {};window.locales.en.student_network.field_options = {...window.locales.en.student_network.field_options, ...{
                           
    "co_founder": "Co-founder",
    "study_partner": "Study partner",
    "collaborators_talent": "Collaborators & talent to hire",
    "funding": "Funding",
    "ideas_inspiration": "Ideas & inspiration",
    "job_opportunities": "Job opportunities",
    "mentorship": "Mentorship",
    "new_friends": "New friends",

              
    "full": "Standard (Recommended)",
    "anonymous": "Anonymous",
    "hidden": "Hidden",

                            
    "popular_interests": "Popular Interests",
    "travel": "Travel",
    "finance": "Finance",
    "entrepreneurship": "Entrepreneurship",
    "art": "Art",
    "education": "Education",
    "marketing": "Marketing",
    "health_care": "Health Care",
    "nonprofit": "Nonprofit",
    "sports": "Sports",
    "technology": "Technology",
    "data_science": "Data Science",
    "blockchain": "Blockchain",
    "product_management": "Product Management",
    "design": "Design",
    "sales": "Sales",
    "leadership": "Leadership",
    "mixology": "Mixology",
    "ai": "AI"
}
};window.locales.es = window.locales.es || {};window.locales.es.student_network = window.locales.es.student_network || {};window.locales.es.student_network.field_options = window.locales.es.student_network.field_options || {};window.locales.es.student_network.field_options = {...window.locales.es.student_network.field_options, ...{
    "co_founder": "Cofundador",
    "study_partner": "Compañero de estudio",
    "collaborators_talent": "Colaboradores y talento para contratar",
    "funding": "Fondos",
    "ideas_inspiration": "Ideas e inspiración",
    "job_opportunities": "Oportunidades de trabajo",
    "mentorship": "Tutoría",
    "new_friends": "Nuevos amigos",
    "full": "Estándar (recomendado)",
    "anonymous": "Anónimo",
    "hidden": "Oculto",
    "popular_interests": "Intereses populares",
    "travel": "Viajar",
    "finance": "Financiar",
    "entrepreneurship": "Emprendimiento",
    "art": "Art",
    "education": "Educación",
    "marketing": "Márketing",
    "health_care": "Cuidado de la salud",
    "nonprofit": "Sin ánimo de lucro",
    "sports": "Deportes",
    "technology": "Tecnología",
    "data_science": "Ciencia de los datos",
    "blockchain": "cadena articulada",
    "product_management": "Gestión del producto",
    "design": "Diseño",
    "sales": "Ventas",
    "leadership": "Liderazgo",
    "mixology": "Mixología",
    "ai": "AI"
}
};window.locales.it = window.locales.it || {};window.locales.it.student_network = window.locales.it.student_network || {};window.locales.it.student_network.field_options = window.locales.it.student_network.field_options || {};window.locales.it.student_network.field_options = {...window.locales.it.student_network.field_options, ...{
    "co_founder": "Cofondatore",
    "study_partner": "Compagno di studio",
    "collaborators_talent": "Collaboratori e talento da assumere",
    "funding": "finanziamento",
    "ideas_inspiration": "Idee e ispirazione",
    "job_opportunities": "Opportunità di lavoro",
    "mentorship": "tutoraggio",
    "new_friends": "Nuovi amici",
    "full": "Standard (consigliato)",
    "anonymous": "Anonimo",
    "hidden": "Nascosto",
    "popular_interests": "Interessi popolari",
    "travel": "Viaggio",
    "finance": "Finanza",
    "entrepreneurship": "Imprenditoria",
    "art": "Arte",
    "education": "Formazione scolastica",
    "marketing": "Marketing",
    "health_care": "Assistenza sanitaria",
    "nonprofit": "Senza scopo di lucro",
    "sports": "Gli sport",
    "technology": "Tecnologia",
    "data_science": "Scienza dei dati",
    "blockchain": "blocco a catena",
    "product_management": "Gestione del prodotto",
    "design": "Design",
    "sales": "I saldi",
    "leadership": "Comando",
    "mixology": "Mixology",
    "ai": "AI"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.student_network = window.locales.zh.student_network || {};window.locales.zh.student_network.field_options = window.locales.zh.student_network.field_options || {};window.locales.zh.student_network.field_options = {...window.locales.zh.student_network.field_options, ...{
    "co_founder": "共同创始人",           
    "study_partner": "学习伙伴",           
    "collaborators_talent": "合作者和人才招聘",           
    "funding": "资助",           
    "ideas_inspiration": "想法与灵感",           
    "job_opportunities": "工作机遇",           
    "mentorship": "导师",           
    "new_friends": "新朋友",           
    "full": "标准（建议）",           
    "anonymous": "匿名",           
    "hidden": "已隐藏",           
    "popular_interests": "共同兴趣",           
    "travel": "旅行",           
    "finance": "金融",           
    "entrepreneurship": "创业",           
    "art": "艺术",           
    "education": "教育背景",           
    "marketing": "市场营销",           
    "health_care": "医疗保健",           
    "nonprofit": "非盈利事业",           
    "sports": "运动",           
    "technology": "技术",           
    "data_science": "数据科学",           
    "blockchain": "区块链",           
    "product_management": "产品管理",           
    "design": "设计",           
    "sales": "销售",           
    "leadership": "领导力",           
    "mixology": "调酒术",           
    "ai": "人工智能"           
}
};