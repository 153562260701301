window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.student_network = window.locales.am.student_network || {};window.locales.am.student_network.partial_access_modal = window.locales.am.student_network.partial_access_modal || {};window.locales.am.student_network.partial_access_modal = {...window.locales.am.student_network.partial_access_modal, ...{
    "explain": "የ {{brandName}} አውታረመረብ የ MBA እና EMBA ዲግሪ ፕሮግራሞች ተማሪዎች እና የቀድሞ ተማሪዎች ለሆኑ ልዩ አለም አቀፍ ማህበር ነው።",           
    "instructions": "ይህንን በባህርይ የተገደበ ቅድመ እይታ ለማሰስ ካርታውን እና ማጣሪያዎቹን ይጠቀሙ።",           
    "deadline": "ቀጣይ የማመልከቻ ቀነ ገደብ፡ <br /><span>{{date}}</span>",           
    "apply_now": "አሁኑኑ ያመልከቱ",           
    "explain_react": "የ{brandName} አውታረ መረብ ለ MBA እና EMBA የዲግሪ መርሃ ግብሮች ተማሪዎች እና ተማሪዎች ብቸኛ አለም አቀፍ ማህበረሰብ ነው።",
    "deadline_react": "የሚቀጥለው የማመልከቻ ገደብ፡ <0/><1>{date}</1>"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.student_network = window.locales.ar.student_network || {};window.locales.ar.student_network.partial_access_modal = window.locales.ar.student_network.partial_access_modal || {};window.locales.ar.student_network.partial_access_modal = {...window.locales.ar.student_network.partial_access_modal, ...{
    "instructions": "استخدم الخريطة والفلاتر لاستكشاف هذه المعاينة محدودة الميزات.",
    "deadline": "الموعد النهائي للتقديم التالي: <br /> <span>{{date}}",
    "apply_now": "قدم الآن",
    "deadline_react": "الموعد النهائي التالي لتقديم الطلبات: <0 /> <1> {date}</1>",
    "explain": "تعد شبكة {{brandName}} مجتمعًا عالميًا حصريًا للطلاب والخريجين.",
    "explain_react": "تعد شبكة {brandName} مجتمعًا عالميًا حصريًا للطلاب والخريجين."
}
};window.locales.en = window.locales.en || {};window.locales.en.student_network = window.locales.en.student_network || {};window.locales.en.student_network.partial_access_modal = window.locales.en.student_network.partial_access_modal || {};window.locales.en.student_network.partial_access_modal = {...window.locales.en.student_network.partial_access_modal, ...{
    "explain": "The {{brandName}} Network is an exclusive global community for students and alumni.",
    "instructions": "Use the map and filters to explore this feature-limited preview.",
    "deadline": "Next application deadline: <br /><span>{{date}}</span>",
    "apply_now": "Apply Now",
    "explain_react": "The {brandName} Network is an exclusive global community for students and alumni.",
    "deadline_react": "Next application deadline: <0/><1>{date}</1>"
}
};window.locales.es = window.locales.es || {};window.locales.es.student_network = window.locales.es.student_network || {};window.locales.es.student_network.partial_access_modal = window.locales.es.student_network.partial_access_modal || {};window.locales.es.student_network.partial_access_modal = {...window.locales.es.student_network.partial_access_modal, ...{
    "instructions": "Usa el mapa y los filtros para explorar esta vista previa con características limitadas.",
    "deadline": "Próxima fecha de solicitud: <br /> <span>{{date}}",
    "apply_now": "Aplica ya",
    "deadline_react": "Próxima fecha límite de solicitud: <0/><1>{date}</1>",
    "explain": "La red {{brandName}} es una comunidad global exclusiva para estudiantes y ex alumnos.",
    "explain_react": "La red {brandName} es una comunidad global exclusiva para estudiantes y ex alumnos."
}
};window.locales.it = window.locales.it || {};window.locales.it.student_network = window.locales.it.student_network || {};window.locales.it.student_network.partial_access_modal = window.locales.it.student_network.partial_access_modal || {};window.locales.it.student_network.partial_access_modal = {...window.locales.it.student_network.partial_access_modal, ...{
    "instructions": "Utilizza la mappa e i filtri per esplorare questa anteprima a funzionalità limitata.",
    "deadline": "Prossima scadenza per le domande: <br /> <span>{{date}}",
    "apply_now": "Applica ora",
    "deadline_react": "Prossima scadenza per le domande: <0/><1>{date}</1>",
    "explain": "La rete {{brandName}} è una community globale esclusiva per studenti ed ex studenti.",
    "explain_react": "La rete {brandName} è una community globale esclusiva per studenti ed ex studenti."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.student_network = window.locales.zh.student_network || {};window.locales.zh.student_network.partial_access_modal = window.locales.zh.student_network.partial_access_modal || {};window.locales.zh.student_network.partial_access_modal = {...window.locales.zh.student_network.partial_access_modal, ...{
    "instructions": "使用地图和筛选器来开放受限的预览功能。",           
    "deadline": "下一个申请截止日期：<br /><span>{{date}}</span>",           
    "apply_now": "立即申请",           
    "deadline_react": "下一个申请截止日期：<0/><1>{date}</1>",           
    "explain": "{{brandName}} 网络是面向学生和校友的专属全球社区。",
    "explain_react": "{brandName} 网络是面向学生和校友的专属全球社区。"
}
};