window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.student_network = window.locales.am.student_network || {};window.locales.am.student_network.network_map_filter_box = window.locales.am.student_network.network_map_filter_box || {};window.locales.am.student_network.network_map_filter_box = {...window.locales.am.student_network.network_map_filter_box, ...{
           
    "students_and_alumni": "ተማሪዎች እና የቀድሞ ተማሪዎች",           
    "events": "ክስተቶች",           

                     
    "no_preferences_selected": "ምንም አጣሪዎች አልተመረጡም",           
    "editing_preferences": "የላቁ የፍለጋ አጣሪዎች...",           
    "plus_n_industries": " (+{count} {count, plural, =1{ኢንዱስትሪ} other{ኢንዱስትሪዎች}})",           
    "plus_n_student_network_looking_for": " (+{count} {count, plural, =1{አማራጭ} other{አማራጮች}})",           
    "plus_n_student_network_interests": " (+{count} {count, plural, =1{ፍላጎት} other{ፍላጎቶች}})",           
    "plus_n_places": " (+{count} {count, plural, =1{ቦታ} other{ቦታዎች}})",           

              
    "network_filter": "የማጣሪያ አውታረመረብ",           
    "keyword_search": "የቁልፍ ቃላት ፍለጋ...",           
    "all": "ሁሉም",           
    "all_classes": "ሁሉም ክፍሎች",           
    "all_mba": "ሁሉም MBA ክፍሎች",
    "all_emba": "ሁሉም የ EMBA ክፍሎች",
    "mine": "የእኔ ክፍሎች",           
    "graduation_status": "ተማሪዎች እና የቀድሞ ተማሪዎች",           
    "not_graduated": "አሁን ያሉ ተማሪዎች",           
    "graduated": "የቀድሞ ተማሪዎች",           
    "apply_filters": "ማጣሪያዎችን ይተግብሩ",           
    "reset_filters": "ማጣሪያዎችን ዳግም ያስጀምሩ",           
    "advanced_search_active": "የላቁ የፍለጋ ንቁ",           
    "edit_search": "ፍለጋ አርትእ",           
    "reset": "ዳግም ያስጀምሩ",           

                       
    "filter_map": "ካርታ ያጣሩ...",           
    "showing_all_events": "ሁሉንም ክስተቶች በማሳየት ላይ",           
    "recommended_events": "የሚመከሩ ክስተቶች...",           

           
    "advanced_search": "የላቀ ፍለጋ...",           
    "return_to_map": "ወደ ካርታ ይመለሱ",           
    "mba": "MBA",
    "emba": "EMBA",
    "executive_education": "አስፈፃሚ ትምህርት",
    "plus_n_class": "(+ {count} {count, plural, =1{ፕሮግራም} other{ፕሮግራሞች}})",
    "msse": "MSSE",
    "msba": "MSBA",
    "quanticDegreePrograms": "የኳንቲክ ዲግሪ ፕሮግራሞች",
    "valarDegreePrograms": "የቫላር ዲግሪ ፕሮግራሞች",
    "execEdPrograms": "አስፈፃሚ የትምህርት ፕሮግራሞች"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.student_network = window.locales.ar.student_network || {};window.locales.ar.student_network.network_map_filter_box = window.locales.ar.student_network.network_map_filter_box || {};window.locales.ar.student_network.network_map_filter_box = {...window.locales.ar.student_network.network_map_filter_box, ...{
           
    "students_and_alumni": "الطلاب والخريجين",
    "events": "أحداث",

                     
    "no_preferences_selected": "لا مرشحات مختارة",
    "editing_preferences": "فلاتر البحث المتقدم ...",
    "plus_n_industries": " (+ {count} {count, plural, =1{صناعة} other{الصناعات}})",
    "plus_n_student_network_looking_for": " (+ {count} {count, plural, =1{أوبتيون} other{أوبتيونس}})",
    "plus_n_student_network_interests": " (+ {count} {count, plural, =1{فائدة} other{الإهتمامات}})",
    "plus_n_places": "(+ {count} {count, plural, =1{وقعك} other{مواقع}})",

              
    "network_filter": "تصفية الشبكة",
    "keyword_search": "بحث بكلمة مفتاحية…",
    "all": "الكل",
    "all_classes": "جميع الفئات",
    "all_mba": "جميع فصول ماجستير إدارة الأعمال",
    "all_emba": "جميع فصول EMBA",
    "mine": "صفي",
    "graduation_status": "الطلاب والخريجين",
    "not_graduated": "الطلاب الحاليون",
    "graduated": "الخريجين",
    "apply_filters": "تطبيق المرشحات",
    "reset_filters": "إعادة تعيين الفلاتر",
    "advanced_search_active": "بحث متقدم نشط",
    "edit_search": "تحرير البحث",
    "reset": "إعادة تعيين",

                       
    "filter_map": "تصفية الخريطة ...",
    "showing_all_events": "عرض جميع الأحداث",
    "recommended_events": "الأحداث الموصى بها ...",

           
    "advanced_search": "البحث المتقدم ...",
    "return_to_map": "العودة إلى الخريطة",
    "mba": "MBA",
    "emba": "EMBA",
    "executive_education": "التعليم التنفيذي",
    "plus_n_class": "(+ {count} {count, plural, =1{برنامج} other{البرامج}})",
    "msse": "MSSE",
    "msba": "مسبا",
    "quanticDegreePrograms": "برامج الدرجات العلمية الكمية",
    "valarDegreePrograms": "برامج درجة فالار",
    "execEdPrograms": "برامج التعليم التنفيذي"
}
};window.locales.en = window.locales.en || {};window.locales.en.student_network = window.locales.en.student_network || {};window.locales.en.student_network.network_map_filter_box = window.locales.en.student_network.network_map_filter_box || {};window.locales.en.student_network.network_map_filter_box = {...window.locales.en.student_network.network_map_filter_box, ...{
           
    "students_and_alumni": "Students & Alumni",
    "events": "Events",

                     
    "no_preferences_selected": "No Filters Selected",
    "editing_preferences": "Advanced Search Filters…",
    "plus_n_industries": " (+{count} {count, plural, =1{industry} other{industries}})",
    "plus_n_student_network_looking_for": " (+{count} {count, plural, =1{option} other{options}})",
    "plus_n_student_network_interests": " (+{count} {count, plural, =1{interest} other{interests}})",
    "plus_n_places": " (+{count} {count, plural, =1{location} other{locations}})",
    "plus_n_class": " (+{count} {count, plural, =1{program} other{programs}})",

              
    "network_filter": "Network Filter",
    "keyword_search": "Keyword Search…",
    "all": "All",
    "all_classes": "All Classes",
    "all_mba": "All MBA Classes",
    "all_emba": "All EMBA Classes",
    "mine": "My Class",
    "graduation_status": "Students & Alumni",
    "not_graduated": "Current Students",
    "graduated": "Alumni",
    "apply_filters": "Apply Filters",
    "reset_filters": "Reset Filters",
    "advanced_search_active": "Advanced Search Active",
    "edit_search": "Edit Search",
    "reset": "Reset",
    "mba": "MBA",
    "emba": "EMBA",
    "executive_education": "Executive Education",
    "msse": "MSSE",
    "msba": "MSBA",

                       
    "filter_map": "Filter Map...",
    "showing_all_events": "Showing all events",
    "recommended_events": "Recommended Events…",

           
    "advanced_search": "Advanced Search…",
    "return_to_map": "Return to Map",

    "quanticDegreePrograms": "Quantic Degree Programs",
    "valarDegreePrograms": "Valar Degree Programs",
    "execEdPrograms": "Executive Education Programs"
}
};window.locales.es = window.locales.es || {};window.locales.es.student_network = window.locales.es.student_network || {};window.locales.es.student_network.network_map_filter_box = window.locales.es.student_network.network_map_filter_box || {};window.locales.es.student_network.network_map_filter_box = {...window.locales.es.student_network.network_map_filter_box, ...{
           
    "students_and_alumni": "Estudiantes y ex alumnos",
    "events": "Eventos",

                     
    "no_preferences_selected": "Sin filtros seleccionados",
    "editing_preferences": "Filtros de búsqueda avanzada ...",
    "plus_n_industries": " (+ {count} {count, plural, =1{industria} other{industrias}})",
    "plus_n_student_network_looking_for": " (+ {count} {count, plural, =1{option} other{options}})",
    "plus_n_student_network_interests": " (+ {count} {count, plural, =1{interesar} other{intereses}})",
    "plus_n_places": "(+ {count} {count, plural, =1{ubicación} other{ubicaciones}})",

              
    "network_filter": "Filtro de red",
    "keyword_search": "Búsqueda por palabra clave…",
    "all": "Todas",
    "all_classes": "Todas las clases",
    "all_mba": "Todas las clases de MBA",
    "all_emba": "Todas las clases de EMBA",
    "mine": "Mi clase",
    "graduation_status": "Estudiantes y Alumni",
    "not_graduated": "Los estudiantes actuales",
    "graduated": "Alumni",
    "apply_filters": "Aplicar filtros",
    "reset_filters": "Restablecer filtros",
    "advanced_search_active": "Búsqueda avanzada activa",
    "edit_search": "Editar búsqueda",
    "reset": "Reiniciar",

                       
    "filter_map": "Filtrar mapa ...",
    "showing_all_events": "Mostrando todos los eventos",
    "recommended_events": "Eventos recomendados ...",

           
    "advanced_search": "Búsqueda Avanzada ...",
    "return_to_map": "Regresar al mapa",
    "mba": "MBA",
    "emba": "EMBA",
    "executive_education": "Educación ejecutiva",
    "plus_n_class": "(+ {count} {count, plural, =1{programa} other{programas}})",
    "msse": "MSSE",
    "msba": "MSBA",
    "quanticDegreePrograms": "Programas de grado cuántico",
    "valarDegreePrograms": "Programas de grado de Valar",
    "execEdPrograms": "Programas de educación ejecutiva"
}
};window.locales.it = window.locales.it || {};window.locales.it.student_network = window.locales.it.student_network || {};window.locales.it.student_network.network_map_filter_box = window.locales.it.student_network.network_map_filter_box || {};window.locales.it.student_network.network_map_filter_box = {...window.locales.it.student_network.network_map_filter_box, ...{
           
    "students_and_alumni": "Studenti e ex studenti",
    "events": "Eventi",

                     
    "no_preferences_selected": "Nessun filtro selezionato",
    "editing_preferences": "Ricerca avanzata Filtri ...",
    "plus_n_industries": " (+ {count} {count, plural, =1{industria} other{industrie}})",
    "plus_n_student_network_looking_for": " (+{count} {count, plural, =1{opzione} other{opzioni}})",
    "plus_n_student_network_interests": " (+ {count} {count, plural, =1{interesse} other{interessi}})",
    "plus_n_places": "(+ {count} {count, plural, =1{posizione} other{posizioni}})",

              
    "network_filter": "Filtro di rete",
    "keyword_search": "Ricerca per parole chiave ...",
    "all": "Tutti",
    "all_classes": "Tutte le classi",
    "all_mba": "Tutte le classi MBA",
    "all_emba": "Tutte le classi EMBA",
    "mine": "La mia classe",
    "graduation_status": "Studenti e Alumni",
    "not_graduated": "Studenti attuali",
    "graduated": "Alumni",
    "apply_filters": "Applica filtri",
    "reset_filters": "Reimposta filtri",
    "advanced_search_active": "Ricerca avanzata attiva",
    "edit_search": "Modifica ricerca",
    "reset": "Reset",

                       
    "filter_map": "Filtro mappa ...",
    "showing_all_events": "Mostra tutti gli eventi",
    "recommended_events": "Eventi consigliati ...",

           
    "advanced_search": "Ricerca Avanzata ...",
    "return_to_map": "Ritorna alla mappa",
    "mba": "MBA",
    "emba": "EMBA",
    "executive_education": "Formazione esecutiva",
    "plus_n_class": "(+ {count} {count, plural, =1{programma} other{programmi}})",
    "msse": "MSE",
    "msba": "MSBA",
    "quanticDegreePrograms": "Programmi di laurea in Quantistica",
    "valarDegreePrograms": "Programmi di laurea Valar",
    "execEdPrograms": "Programmi di formazione per dirigenti"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.student_network = window.locales.zh.student_network || {};window.locales.zh.student_network.network_map_filter_box = window.locales.zh.student_network.network_map_filter_box || {};window.locales.zh.student_network.network_map_filter_box = {...window.locales.zh.student_network.network_map_filter_box, ...{
           
    "students_and_alumni": "学员与校友",           
    "events": "活动",           

                     
    "no_preferences_selected": "未选择筛选器",           
    "editing_preferences": "高级搜索筛选器",           
    "plus_n_industries": " (+{count} {count, plural, =1{行业} other{行业}})",           
    "plus_n_student_network_looking_for": " (+{count} {count, plural, =1{选项} other{选项}})",           
    "plus_n_student_network_interests": " (+{count} {count, plural, =1{兴趣} other{兴趣}})",           
    "plus_n_places": " (+{count} {count, plural, =1{地点} other{地点}})",           

              
    "network_filter": "网络滤波器",           
    "keyword_search": "关键词搜索",           
    "all": "所有",           
    "all_classes": "所有班级",           
    "all_mba": "所有MBA课程",
    "all_emba": "所有EMBA课程",
    "mine": "我的班级",           
    "graduation_status": "学员与校友",           
    "not_graduated": "当前的学员",           
    "graduated": "校友",           
    "apply_filters": "应用筛选器",           
    "reset_filters": "重置筛选器",           
    "advanced_search_active": "高级搜索有效",           
    "edit_search": "编辑搜索",           
    "reset": "重置",           

                       
    "filter_map": "筛选地图...",           
    "showing_all_events": "显示所有活动",           
    "recommended_events": "推荐活动",           

           
    "advanced_search": "高级搜索",           
    "return_to_map": "返回地图",           
    "mba": "MBA",
    "emba": "EMBA",
    "executive_education": "高管教育",
    "plus_n_class": "(+ {count} {count, plural, =1{程序} other{程式}})",
    "msse": "MSSE",
    "msba": "工商管理硕士",
    "quanticDegreePrograms": "量子学位课程",
    "valarDegreePrograms": "Valar 学位课程",
    "execEdPrograms": "高管培训项目"
}
};